import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Collapse,
  Divider,
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import { CallStockReport } from "../../../../Api/Reports/InventoryApi";
import moment from "moment";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import {
  CallBranchsList,
  CallBrands,
  CallProductCategories,
  CallProductGroups,
  CallProductSearch,
  CallWarehouses,
} from "../../../../Api/Reports/CommonReportApi";
// import VBInputField from "../../../../Components/Utils/VBInputField";
import { useDispatch, useSelector } from "react-redux";
import VBInputField from "../../../../Components/Utils/VBInputField";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { formatNumber } from "../../../../generalFunction";
import { goToInvoice } from "../../../../Function/comonFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import LoaderLite from "../../../../Components/Utils/LoaderLite";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { UserRolePermission } from "../../../../Function/Editions";
// import VBInputField from "@/Utils/VBInputField";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "react-i18next";

const StockReport = () => {
  let Export_view = UserRolePermission("Stock Report", "print_permission");
  let Parchase_Price_Permisssion = UserRolePermission("Stock Report", "purchase_price")

  console.log(Parchase_Price_Permisssion,Export_view,"===============>>Parchase price");
  //Set Values 📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦📦
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [progress, setProgress] = useState(0)
  const [clearFilter, setClearFilter] = useState(false)
  const [progressMessage, setProgressMessage] = useState("Generating Report...")
  const [reportData, setReportData] = useState({data: [], count: 0});
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableBranch} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [disableArray, setDisableArray] = useState([]);
  const [isFilterApisComplete, setIsFilterApisComplete] = useState(false);
  const [t, i18n] = useTranslation("common");
  const [filterOptions, setFilterOptions] = useState({
    allProductActive: true,
    stockLevelActive: false,
    stockLevel: "All Stock",
    barcode: "",
    werehouse: [],
    Branches: [],
    BranchAllWarehouseIDs: [],
    BranchIds: [],
    productCategories: null,
    productGroup: null,
    brandList: null,
    productname: null,
    fromDate: moment(),
    toDate: moment(),
    showActiveProductsOnly:true
  });

  const [filterData, setFilterData] = useState({
    productList: [],
    werehouseList: [],
    productCategoriesList: [],
    productGroupsList: [],
    brandList: [],
    BranchList: [],
    IsSpecificBranchID :0,
    IsSpecific : false,
    stockLevelList: [
      "All Stock",
      "Positive Stock",
      "Negative Stock",
      "Zero Stock",
      "Below Recorder Level",
      "Above Max Level",
      "Below Min Stock",
    ],
  });
  const [search, setSearch] = useState({
    product: "",

  })
  
  const [isLoading, setIsLoading] = useState({
    isLoadingProduct: false,
  })
  const [filterArray, setFilterArray] = useState([]);

  //Handle functions 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧
  const closeFilter = () => setOpenFilter(false);

  const handleClearFilter = function () {
    let BranchIDList = filterData.BranchList?.filter((i) => {
      if (i.BranchID === BranchID) {
        return i;
      }
    });
    let Branches = [];
    Branches.push(BranchIDList[0]);
    setFilterOptions({
      allProductActive: true,
      stockLevelActive: false,
      stockLevel: "All Stock",
      barcode: "",
      werehouse: [],
      productCategories: null,
      productGroup: null,
      brandList: null,
      productname: null,
      fromDate: moment(),
      toDate: moment(),
      BranchIds: [],
      Branches,
      BranchAllWarehouseIDs : filterOptions.BranchAllWarehouseIDs,
    });
    setSearch({
      product: ""
    })
    setClearFilter(!clearFilter)
    setPage(1)
  }

  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "Branches") {
        let BranchIds = value.map((e) => e.BranchID);
        setFilterOptions({
          ...filterOptions,
          Branches: value,
          BranchIds: BranchIds,
          werehouse : [],
        });
      } else {
        setFilterOptions({
          ...filterOptions,
          [name]: value,
        });
      }
    }
  };

  const productCheckChange = (name) => {
    setFilterOptions({
      ...filterOptions,
      productCategories: null,
      productGroup: null,
      brandList: null,
      productname: null,
      [name]: !filterOptions[name],
    });
  };

  const stockRadioChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      stockLevel: e.target.value,
    });
  };

  const searchReport = function () {
    setPage(1)
    fetchReport();
  };

  const handleValidation = function () {
    let isValid = true;
    if (filterOptions.Branches.length === 0) {
      isValid = false;
    }
    return isValid;
  };

  // API Fetch data 📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥📥
  const fetchReport = async function () {
    let valid = handleValidation();
    if (valid) {
      console.log(
        filterData.stockLevelList.indexOf(filterOptions.stockLevel),
        filterOptions.stockLevel,
        "@#$%^&*()"
      );
      setIsLoading({ ...isLoading, isLoadingProduct: true });
      const payload = {
        WareHouseID: filterOptions.werehouse.length === 0 ? [0, ...filterOptions.BranchAllWarehouseIDs] :[0, ...filterOptions.werehouse.map((item) => item.WarehouseID)],
        GroupID: 0,
        BrandID: 0,
        CategoryID: 0,
        StockFilter:
          filterData.stockLevelList.indexOf(filterOptions.stockLevel) + 1,
        Barcode: "0",
        FromDate: filterOptions.toDate.format("YYYY-MM-DD"),
        ToDate: filterOptions.fromDate.format("YYYY-MM-DD"),
        ProductFilter: 1,
        ProductID: 0,
        page_no: page,
        items_per_page: appSetting.itemPerPage,
        CompanyID: CompanyID,
        BranchID:filterData.IsSpecific ? filterData.IsSpecificBranchID : BranchID,
        PriceRounding: Number(PriceDecimalPoint),
        CreatedUserID: user_id,
      };
      // if (filterOptions.werehouse)
      //   payload.WareHouseID = [
      //     0,
      //     ...filterOptions.werehouse.map((item) => item.WarehouseID),
      //   ];
      if (filterOptions.productCategories)
        payload.CategoryID = filterOptions.productCategories.ProductCategoryID;
      if (filterOptions.productGroup)
        payload.GroupID = filterOptions.productGroup.ProductGroupID;
      if (filterOptions.brandList)
        payload.BrandID = filterOptions.brandList.BrandID;
      if (filterOptions.barcode) payload.Barcode = filterOptions.barcode + "";
      if (filterOptions.productname)
        payload.ProductID = filterOptions.productname.ProductID;
      console.log(filterOptions.brandList);

      try {
        const data = await CallStockReport(payload);
        console.log(data);
        if (data.StatusCode === 6000) {
          setReportData({ data: data.data, count: data.count });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: data?.message || "Data Not Found",
              severity: "warning",
            })
          );
          setReportData({ data: [], count: 0 });
        }
        setIsLoading({ ...isLoading, isLoadingProduct: false });
      } catch (error) {
        console.error("error is in CallStockReport API :\n", error);
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: "Select a Branch",
          severity: "warning",
        })
      );
    }
  };

  const searchProduct = async function () {
    setIsLoading({...isLoading, isLoadingProduct: true})
    const callProduct = await CallProductSearch({
      product_name: search.product,
      length: 1,
      type: "StockReport",
      is_product_image: false,
      CompanyID: CompanyID,
      BranchID: filterData.IsSpecific ? filterData.IsSpecificBranchID : BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      showActiveProductsOnly: filterOptions.showActiveProductsOnly,
    });
    if (callProduct.StatusCode === 6000) {
      setFilterData((prev) => ({ ...prev, productList: callProduct.data }));
      setIsLoading({...isLoading, isLoadingProduct: false})
    } else if (callProduct.StatusCode === 6001) {
      setFilterData((prev) => ({ ...prev, productList: [] }));
      setIsLoading({...isLoading, isLoadingProduct: false})
    }
  };

  const handleBranchids = async function () {
    const warehouse = await CallWarehouses({
      CompanyID: CompanyID,
      BranchIDs:
        filterOptions?.BranchIds?.length === 0
          ? BranchID
          : filterOptions.BranchIds,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      BranchFilter: filterOptions.BranchIds?.length === 0 ? false : true,
      show_all: true,
    });
    if (warehouse?.StatusCode === 6000) {
      setFilterData((prev) => ({ ...prev, werehouseList: warehouse.data }));
      const WareHouseIDs = warehouse.data.map(item => item.WarehouseID);
        setFilterOptions((prev) => ({
          ...prev,
          BranchAllWarehouseIDs: WareHouseIDs
        }));

      // setFilterOptions((prev) => ({
      //   ...prev,
      //   BranchAllWarehouse: warehouse.data,
      // }));
    }
    console.log(warehouse, "rinnnnnnnnnnnnnnnnnnnnnnn");
  };

  const filterApis = async function () {
    const warehouse = await CallWarehouses({
      CompanyID: CompanyID,
      BranchID: filterData.IsSpecific ? filterData.IsSpecificBranchID : BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all: true,
    });
    console.log(warehouse);
    if (warehouse?.StatusCode === 6000) {
      setFilterData((prev) => ({ ...prev, werehouseList: warehouse.data }));
    }

    const productCategories = await CallProductCategories({
      CompanyID: CompanyID,
      BranchID: filterData.IsSpecific ? filterData.IsSpecificBranchID : BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });
    console.log(productCategories);
    if (productCategories.StatusCode === 6000) {
      setFilterData((prev) => ({
        ...prev,
        productCategoriesList: productCategories.data,
      }));
    }

    const productGroups = await CallProductGroups({
      CompanyID: CompanyID,
      BranchID: filterData.IsSpecific ? filterData.IsSpecificBranchID : BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });
    console.log(productGroups);
    if (productGroups.StatusCode === 6000) {
      setFilterData((prev) => ({
        ...prev,
        productGroupsList: productGroups.data,
      }));
    }

    const brands = await CallBrands({
      CompanyID: CompanyID,
      BranchID: filterData.IsSpecific ? filterData.IsSpecificBranchID : BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });
    console.log(brands);
    if (brands.StatusCode === 6000) {
      setFilterData((prev) => ({ ...prev, brandList: brands.data }));
    }
  };
  

  const handleBranchList = async function () {
    const BranchList = await CallBranchsList({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      show_all: true,
    });
    if (BranchList.StatusCode === 6000) {
      let Branches = [];
      let BranchIdList = BranchList.data.filter((i, e) => {
        if (i.BranchID === BranchID) {
          return i;
        }
      });
      Branches.push(BranchIdList[0]);
      setFilterOptions({
        ...filterOptions,
        Branches,
      });
      setFilterData((prev) => ({
        ...prev,
        BranchList: BranchList.data,
      }));
    }
  }

  const DisabledBranches = function (newValue) {
    console.log(newValue, filterData?.BranchList,"lllllllllllllllllllllllllllllllll");
    let indices = filterData?.BranchList.reduce((acc, obj, i) => {
      // console.log(newValue[newValue.length - 1], "Reeeeeeeeeeeee");
      if (
        newValue[newValue?.length - 1]?.IsProductSpecific ||
        (newValue?.length !== 0 &&
          !newValue[newValue?.length - 1]?.IsProductSpecific ===
            obj.IsProductSpecific)
      ) {
        console.log(obj, i, acc, "Reeeeeeeeeeeee");
        acc.push(i);
      }
      return acc;
    }, []);
    let BranchIdS = newValue[newValue?.length - 1]?.IsProductSpecific
    console.log(BranchIdS,'::::::::::::::::::::::::::::dddddddd::::::::::::');
    if (BranchIdS){
      setFilterData((prev) => ({
        ...prev,
        IsSpecificBranchID:newValue[0].BranchID,
        IsSpecific:true,
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        IsSpecific:false,
      }));
    }
    // let indices = filterData.BranchList
    setDisableArray(indices);
    console.log(indices, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<");
  };

  console.log(filterOptions.Branches, "Branchessssssssssssssss");
  console.log(filterOptions.BranchAllWarehouseIDs, "filterOptions.BranchAllWarehouseIDs",filterOptions.werehouse);
  console.log(filterData.werehouseList, "WAreeeeeeeeeehdddddddsese");

  console.log(filterData.IsSpecificBranchID, "Specificcccccccccccccccccccccccccc");
  

  // UseEffect Hell 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
  useEffect(() => {
    const runFilterApis = async () => {
      await handleBranchList();
      setIsFilterApisComplete(true);
    };
    filterApis();
    runFilterApis();
    searchProduct();
  }, []);

  useEffect(() => {
    searchProduct();
  }, [search.product]);

  useEffect(() => {
    if (isFilterApisComplete) {
      fetchReport();
    }
  }, [isFilterApisComplete, page, clearFilter,]);

  useEffect(() => {
    handleBranchids();
  }, [filterOptions.BranchIds]);

  useEffect(() => {
    DisabledBranches(filterOptions.Branches)
    filterApis()
    searchProduct();
    handleBranchids();
  }, [filterOptions.Branches])
  


  console.log(filterOptions.productname);
  console.log(disableArray,'DDISAAAAAAAAAAAAAAAAAAAAAAAAAA');


  return (
    <>
             {progress > 0 ?
      // <LoaderLite message={"Generating Report... " + progress + "%"} progress={progress} fullscreen={true} />
      <LoaderLite message={((progress<=100)?("Generating Report... " + progress + "%"):"Creating File... ")} progress={progress} fullscreen={true} />
      :null}
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{ color:"#001746", fontSize: "23px", fontWeight: "500",marginRight:"20px"}}
              variant="h2"
            >
              {t("Stock Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            {/* <ExportBtn /> */}
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"stock_report"}
              FromDate={filterOptions.toDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.fromDate.format("YYYY-MM-DD")}
              ProductFilter={1}
              StockFilter={filterData.stockLevelList.indexOf(filterOptions.stockLevel) + 1}
              ProductID={filterOptions.productname ? filterOptions.productname.ProductID : 0}
              Barcode={filterOptions.barcode ? filterOptions.barcode : 0}
              CategoryID={filterOptions.productCategories ? filterOptions.productCategories.ProductCategoryID : 0}
              GroupID={filterOptions.productGroup ? filterOptions.productGroup.ProductGroupID : 0}
              // WarehouseID={filterOptions.werehouse.length > 0 ? [0, ...filterOptions.werehouse.map(item => item.WarehouseID)] : [0]}
              WarehouseID={filterOptions.werehouse.length === 0 ? [0, ...filterOptions.BranchAllWarehouseIDs] :[0, ...filterOptions.werehouse.map((item) => item.WarehouseID)]}
              BrandID={filterOptions.brandList ? filterOptions.brandList.BrandID : 0}
              is_celery={true}
              setProgress={setProgress}
              setProgressMessage={setProgressMessage}
            />
            : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData.count}
              page_no={page}
              setPage_no = {setPage}
            />
          </Box>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FilterLable
              name={t("Date")}
              value={filterOptions.fromDate.format("DD/MM/YYYY")}
            />
          {(filterOptions.allProductActive || filterOptions.productname) && (
            <FilterLable
              name={t("Products")}
              value={filterOptions.allProductActive ? "All Products" : filterOptions.productname.ProductName}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  allProductActive: !filterOptions.allProductActive,
                  productname: null,
                })
              }
            />
          )}

          {filterOptions.barcode && <FilterLable
              name={t("Barcode")}
              value={filterOptions.barcode}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  barcode: '',
                })
              }
          />}

          {filterOptions.stockLevelActive && <FilterLable
              name={t("Stock Level")}
              value={filterOptions.stockLevel}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  stockLevelActive: false,
                })
              }
          />}

            {EnableBranch && BranchID === 1 && filterOptions.Branches.length !== 0 && <FilterLable
              name={t("Branch Name")}
              value={filterOptions.Branches?.map((e, i)=> 
                <Chip 
                key={i} 
                label={e.NickName} 
                variant="outlined" 
                sx={{ p: "1px", fontSize: "10px", height: "22px", mr: "4px", 
                  ".MuiChip-label": {
                    px: '8px',
                  },
                  ".MuiSvgIcon-root": {
                    fontSize: "15px",
                    color: "#ff00006b",
                  }
                }}
                onDelete={() =>  
                  setFilterOptions({
                    ...filterOptions,
                    Branches: filterOptions.Branches.filter(l => l.BranchID !== e.BranchID)
                  })
                }
                />
              )}
              handleDelete={() => setFilterOptions({...filterOptions, Branches: []})}
            />
          }


          {filterOptions.werehouse.length !== 0 && <FilterLable
              name={t("Warehouse Name")}
              value={filterOptions.werehouse.map((e, i)=> 
                <Chip 
                key={i} 
                label={e.WarehouseName} 
                variant="outlined" 
                sx={{ p: "1px", fontSize: "10px", height: "22px", mr: "4px", 
                  ".MuiChip-label": {
                    px: '8px',
                  },
                  ".MuiSvgIcon-root": {
                    fontSize: "15px",
                    color: "#ff00006b",
                  }
                }}
                onDelete={() =>  
                  setFilterOptions({
                    ...filterOptions,
                    werehouse: filterOptions.werehouse.filter(l => l.WarehouseID !== e.WarehouseID)
                  })
                }
                />
              )}
              handleDelete={() => setFilterOptions({...filterOptions, werehouse: []})}
            />
          }

{(filterOptions.allProductActive && filterOptions.productCategories) && <FilterLable
              name={t("Product Category")}
              value={filterOptions.productCategories.ProductCategoryName}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  productCategories: null,
                })
              }
          />}

{(filterOptions.allProductActive && filterOptions.productGroup) && <FilterLable
              name={t("Group Name")}
              value={filterOptions.productGroup.GroupName}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  productGroup: null,
                })
              }
          />}

{(filterOptions.allProductActive && filterOptions.brandList) && <FilterLable
              name={t("Brand")}
              value={filterOptions.brandList.BrandName}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  brandList: null,
                })
              }
          />}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Auto Barcode")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Product Code")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Product")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Stock In Base Unit")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Current Stock")} </VBTableCellHeader>
            {Parchase_Price_Permisssion && (
              <VBTableCellHeader sx={{ textAlign: "right" }}>
                {t("Purchase Price")}
              </VBTableCellHeader>
            )}
            {/* <VBTableCellHeader sx={{ textAlign: "right"}}>Purchase Price </VBTableCellHeader> */}
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Sales Price")} </VBTableCellHeader>
          </VBTableHeader>
          {isLoading.isLoadingProduct ? <VBSkeletonLoader /> : <TableBody>
            {reportData.data.map((data, i) => (
              <TableRow                 
                // sx={{ cursor: data.id === "nan" || "" ? "auto" : "pointer" }} 
                // onClick={() => data.id === "nan" || "" ? null : goToInvoice(data.id, "Create Product",)}
              >
                <VBTableCellBody>{(page - 1) * appSetting.itemPerPage + i + 1}</VBTableCellBody>
                <VBTableCellBody>{data.Barcode}</VBTableCellBody>
                <VBTableCellBody>{data.ProductCode}</VBTableCellBody>
                <VBTableCellBody>{data.ProductName}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(data.StockInBaseUnit)} {data.BaseUnitName} </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(data.CurrentStock)} {data.UnitName} </VBTableCellBody>

                {Parchase_Price_Permisssion && (
                  <VBTableCellBody sx={{ textAlign: "right" }}>
                    {formatNumber(data.PurchasePrice)}
                  </VBTableCellBody>
                )}
                {/* <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(data.PurchasePrice)}</VBTableCellBody> */}
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(data.SalesPrice)}</VBTableCellBody>
              </TableRow>
            ))}

            <TableRow></TableRow>
          </TableBody>}
          {/* <VBTableFooter >
          <TableRow>
            <VBTableCellFooter ></VBTableCellFooter>
            <VBTableCellFooter >{reportData[1]?.LedgerName}</VBTableCellFooter>
            <VBTableCellFooter align="right">{reportData[1]?.Amount}</VBTableCellFooter>
          </TableRow>
        </VBTableFooter> */}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        handleDefaultFilter={handleClearFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                fromOnChange={(e) =>
                  setFilterOptions((prev) => ({ ...prev, fromDate: e }))
                }
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("All Products"),
                    checked: filterOptions.allProductActive,
                    onChange: () => productCheckChange("allProductActive"),
                  },
                ]}
              />
            </ListItem>
            <ListItem>
            <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Show Inactive Products"),
                    checked: filterOptions.showActiveProductsOnly? false : true,
                    onChange: () => {
                      productCheckChange("showActiveProductsOnly")
                      searchProduct()},
                  },
                ]}
              />
            </ListItem>
            <ListItem>
              {/* Product Search AutoComplete */}
              <ViknSearchAutoComplete
                freeSolo
                // disabled={!filterOptions.productname}
                filterName={t("Product")}
                placeholder={"Search for Product"}
                loading={isLoading.isLoadingProduct}
                options={filterData.productList}
                getOptionLabel={(option) => option.ProductName || ""}
                // name="partyObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    setSearch({...search, product: value})
                  }
                }}
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.ProductName && option.ProductName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.ProductCode && option.ProductCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.ProductCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.ProductName}</Box>
                  </Box>
                )}
                onChange={(e, v) =>{ 
                  // console.log(e, v);
                  // handleAutoComplete(e, v, "productname") 
                  setFilterOptions({ // make other state null
                    ...filterOptions,
                    productname: v,
                    allProductActive: false,
                    barcode: "",
                  })
                }}
                value={filterOptions.productname}
              />
            </ListItem>
            <ListItem>
              {/* Product Search AutoComplete */}
              {/* <ViknSearchAutoComplete
                filterName={"Barcode"}
                placeholder={"Search for Barcode"}
              /> */}
              <VBInputField
                label={t("Barcode")}
                type="number"
                placeholder={"Search for Barcode"}
                value={filterOptions.barcode}
                // onChange={(e) => {
                //   // handleAutoComplete(e, null, "productname") 
                //   setFilterOptions((prev) => ({
                //     ...prev,
                //     productname: null,
                //     barcode: e.target.value,
                //   }))
                //   setSearch({
                //     ...search,
                //     product: ""
                //   })
                // }}
                onChange={(e) => {
                  setFilterOptions((prev) => ({
                    ...prev,
                    productname: null, // clear the product name
                    barcode: e.target.value,
                  }))
                  setSearch({
                    ...search,
                    product: "" // clear the product search
                  })
                  // Add this line to clear the ViknSearchAutoComplete field
                }}
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Stock Level"),
                    checked: filterOptions.stockLevelActive,
                    onChange: () => productCheckChange("stockLevelActive"),
                  },
                ]}
              />
            </ListItem>
            {/* <Divider /> */}
            <Collapse
              in={filterOptions.stockLevelActive}
              timeout="auto"
              unmountOnExit
            >
              {" "}
              <ListItem>
                <ViknRadioGroup
                  handleChange={stockRadioChange}
                  radios={filterData.stockLevelList.map((i) => ({
                    label: i,
                    value: i,
                  }))}
                  value={filterOptions.stockLevel}
                  // labelName={""}
                />
              </ListItem>
            </Collapse>
            {EnableBranch && BranchID === 1 && (
              <>
              <ListItem sx={{display : 'flex',flexDirection:'column'}}>
                <span style={{ display: "flex", alignItems: "center",justifyContent:'end',width:'100%',padding:'2px'}}>
                  <Tooltip title="Select branches for your search. Specific branches allow one selection; non-specific branches allow multiple selections.">
                      <InfoOutlinedIcon style={{ color: "#066385", fontSize: "18px "}} />
                  </Tooltip>
                </span>
                <ViknAutoComplete
                  multiple
                  value={filterOptions.Branches}
                  placeholder={"Select a Branch"}
                  options={filterData.BranchList}
                  getOptionLabel={(option) => option.NickName}
                  onChange={(e, newValue) => {
                    handleAutoComplete(e, newValue, "Branches");
                    DisabledBranches(newValue);
                    // let indices = filterData.BranchList.reduce((acc, obj, i) => {
                    //   // console.log(newValue[newValue.length - 1], "Reeeeeeeeeeeee");
                    //   if (newValue[newValue?.length - 1]?.IsProductSpecific || (newValue?.length !== 0 && !newValue[newValue?.length - 1]?.IsProductSpecific === obj.IsProductSpecific)) {
                    //     console.log(obj, i, acc, "Reeeeeeeeeeeee");
                    //     acc.push(i);
                    //   }
                    //   return acc;
                    // }, []);
                    // // let indices = filterData.BranchList
                    // setDisableArray(indices)
                    // // console.log(indices, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<");
                  }}
                  getOptionDisabled={(option) =>
                    disableArray.includes(filterData.BranchList.indexOf(option))
                  }
                  error={filterOptions?.Branches.length === 0 ? true : false}
                />
              </ListItem>
              </>
            )}
            <ListItem>
              <ViknAutoComplete
                multiple
                value={filterOptions.werehouse}
                placeholder={"Warehouse Name"}
                options={filterData.werehouseList}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleAutoComplete(e, newValue, "werehouse")
                }
              />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            {filterOptions.allProductActive && [
              <ListItem>
                <ViknAutoComplete
                  placeholder={"Product Category"}
                  options={filterData.productCategoriesList}
                  getOptionLabel={(option) => option.ProductCategoryName}
                  value={filterOptions.productCategories}
                  onChange={(e, newValue) =>
                    handleAutoComplete(e, newValue, "productCategories")
                  }
                />
              </ListItem>,

              <ListItem>
                <ViknAutoComplete
                  placeholder={"Product Group"}
                  options={filterData.productGroupsList}
                  getOptionLabel={(option) => option.GroupName}
                  value={filterOptions.productGroup}
                  onChange={(e, newValue) =>
                    handleAutoComplete(e, newValue, "productGroup")
                  }
                />
              </ListItem>,

              <ListItem>
                <ViknAutoComplete
                  placeholder={"Brands"}
                  options={filterData.brandList}
                  getOptionLabel={(option) => option.BrandName}
                  value={filterOptions.brandList}
                  onChange={(e, newValue) =>
                    handleAutoComplete(e, newValue, "brandList")
                  }
                />
              </ListItem>,
            ]}
          </List>
        }
      />
    </>
  );
};

export default StockReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "22%",
}));
