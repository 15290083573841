import "./Api/mainAxios";
import "@fontsource/poppins";
import { useState } from "react";
import {
  // Box,
  // Card,
  // CardContent,
  // CardHeader,
  // Container,
  // FormControlLabel,
  // FormGroup,
  // Switch,
  // Typography,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainPage from "./MainPage";
import { BrowserRouter } from "react-router-dom";
import { darkTheme, lightTheme } from "./theme";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";


const state = store.getState();
const language = state.appSettingsSclice.language;

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: language,
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    ar: {
      common: common_ar,
    },
  },
});

const App = () => {

  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const changeTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  // document.addEventListener('keydown', function(event) {
  //   // Check if Ctrl key and V key are pressed simultaneously
  //   if (event.ctrlKey && event.key === 'd') {
  //     // Handle the Ctrl + V (paste) event
  //     // console.log('Ctrl + V (paste) detected');
      
  //     // Prevent the default paste behavior (optional)
  //     event.preventDefault();
  //     setIsDarkTheme(!isDarkTheme)
  //   }
  // });
  

  return (
    <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <BrowserRouter>
        {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
        <ThemeProvider
          theme={isDarkTheme ? createTheme(darkTheme) : createTheme(lightTheme)}
        >
          <CssBaseline />
          <MainPage />
          {/* <FormGroup
            sx={{ position: "absolute", bottom: 0, left: 1 }}
          >
            <FormControlLabel
              control={<Switch checked={isDarkTheme} onChange={changeTheme} />}
              label={!isDarkTheme ? "Dark Theme" : "Light Theme"}
            />
          </FormGroup> */}
          {/* <Container>
        <div className="App">
          <Box component="div" p={5}></Box>
          <Card>
            <CardHeader
              action={
                
              }
            />
            <CardContent>
              <Typography variant="h3" component="h3">
                Kindacode.com
              </Typography>
              <Typography variant="body1">
                Dark Mode is {isDarkTheme ? "On" : "Off"}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Container> */}
        </ThemeProvider>
        {/* </LocalizationProvider> */}
      </BrowserRouter>
    </Provider>
    </I18nextProvider>
  );
};

export default App;
