import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";

const CreateCatogeryAPI = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v10/productCategories/create-productCategory/",
      data: body,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const ProductCategoryListAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v11/products/productCategories/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in CallProductCategories");
  }
};

const ProductCatogeryDeleteAPI = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/productCategories/delete/productCategory/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const ProductCatogeryDetailsAPI = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/productCategories/view/productCategory/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const ProductCatogeryUpdateAPI = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/productCategories/edit/productCategory/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const BrandAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v10/brands/brands/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in CallProductCategories");
  }
};

const BrandDeleteAPI = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/brands/delete/brand/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const CreateBrandAPI = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v10/brands/create-brand/",
      data: body,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const BrandUpdateAPI = async function (body, id) {
  try {
    const { data } = await axios.post("api/v10/brands/edit/brand/" + id + "/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const BrandDetailsAPI = async function (body, id) {
  try {
    const { data } = await axios.post("api/v10/brands/view/brand/" + id + "/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const SetDefultBrandAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v10/brands/setDefault-brands/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in CallProductCategories");
  }
};

const ProductGroupAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v10/productGroups/productGroups/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in CallProductGroups");
  }
};

const ProductGroupDeleteAPI = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/productGroups/delete/productGroup/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const SetDefultProductAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/productGroups/setDefault-productGroups/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallProductCategories");
  }
};

const KitchenListAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v10/posholds/list/pos-kitchen/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in CallProductCategories");
  }
};
const CreateProductGroupAPI = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v10/productGroups/create-productGroup/",
      data: body,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const ProductGroupDetailsAPI = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/productGroups/view/productGroup/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const ProductGroupUpdateAPI = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/productGroups/edit/productGroup/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};
const BrandSearchAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v10/brands/search-brand-list/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in API");
  }
};
const ProductGroupSearchAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/productGroups/search-productGroup-list/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in API");
  }
};
const ProductCategorySearchAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/productCategories/search-productCategory-list/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in API");
  }
};

const GetRowMaterialUrl = async function(body) {
  try {
    const { data } = await axios.post(
      "api/v11/products/list-rawmaterial/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in API");
  }
};

const GetSingleRowmaterialUrl = async function(body) {
  try {
    const { data } = await axios.post(
      "api/v11/products/single-rawmaterial/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in API");
  }
};

const ProductListUrl = async function name(body) {
    try {
      const { data } = await axios.post(
        "api/v11/products/products-test/",
        {
          ...body,
        }
      );
      return data;
    } catch (error) {
      console.log(error);
      
    }
}

const CreateProductURL = async function name(body) {
  try {
    const { data } = await axios({
      method: "post",
      url: "api/v10/products/create-product/",
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return data;
  } catch (error) {
    console.log(error);
    return error
  }
}

const ProductDetailsAPI = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v11/products/view/product/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const ProductsAllURL = async function (params) {
  try {
    const { data } = await axios(
      "api/v11/products/fetch/create-data/",
      {
        params: params
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const ProductDeleteURL = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/products/delete/product/1/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const ProductEditURL = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/products/edit/product/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};


export {
  CreateCatogeryAPI,
  ProductCategoryListAPI,
  ProductCatogeryDeleteAPI,
  ProductCatogeryDetailsAPI,
  ProductCatogeryUpdateAPI,
  BrandAPI,
  BrandDetailsAPI,
  CreateBrandAPI,
  BrandUpdateAPI,
  BrandDeleteAPI,
  SetDefultBrandAPI,
  ProductGroupAPI,
  ProductGroupDeleteAPI,
  ProductGroupDetailsAPI,
  SetDefultProductAPI,
  KitchenListAPI,
  CreateProductGroupAPI,
  ProductGroupUpdateAPI,
  BrandSearchAPI,
  ProductGroupSearchAPI,
  ProductCategorySearchAPI,
  GetRowMaterialUrl,
  GetSingleRowmaterialUrl,
  ProductListUrl,
  CreateProductURL,
  ProductDetailsAPI,
  ProductsAllURL,
  ProductDeleteURL,
  ProductEditURL
};
