import React, { useMemo, useState } from "react";
import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Button,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Popper,
  styled,
  TableBody,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableSwitch from "../../../../Components/Utils/TableSwitch";

function MultiUnits({
  dataDetail,
  setDataDetail,
  AddNewMultiUnitLine,
  dataList,
}) {
  const [t] = useTranslation("common");

  const [isAction, setisAction] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const openAction = Boolean(isAction);

  const handleChangeFun = (value, index, name) => {
    let list = dataDetail.multiUnit;
    list[index][name] = value;

    switch (name) {
      case "MultiFactor":
        list[index]["SalesPrice"] = value * list[0]["SalesPrice"];
        list[index]["PurchasePrice"] = value * list[0]["PurchasePrice"];
        break;
      case "ReverseFactor":
        list[index]["SalesPrice"] = value / list[0]["SalesPrice"];
        list[index]["PurchasePrice"] = value / list[0]["PurchasePrice"];
        list[index]["MultiFactor"] = 1 / value;
        break;

      case "UnitInSales":
      case "UnitInReports":
      case "UnitInPurchase":
        list = list.map((e, v) =>
          index === v
            ? e
            : {
                ...e,
                [name]: false,
              }
        );
        break;

      default:
        break;
    }

    setDataDetail({
      ...dataDetail,
      multiUnit: list,
    });
  };

  return (
    <VBTableContainer>
      <VBTableHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("Unit")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("Multi(f)")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("Rev(f)")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("purchase(p)")}{" "}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("sales(s)")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("barcode")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("sales(p)1")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("sales(p)2")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("sales(p)3")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("unit in sales")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("unit in Purchase")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("unit in report")}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ textTransform: "uppercase" }}>
          {t("action")}
        </VBTableCellHeader>
      </VBTableHeader>
      <TableBody>
        {dataDetail.multiUnit.map((ele, ind) => (
          <TableRow key={ind + 1}>
            <VBTableCellBody
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
                borderRadius: "4px",
              }}
            >
              {ind === 0 ? (
                <span style={{ width: "81px" }}> {ele?.UnitName} </span>
              ) : (
                <Autocomplete
                  disablePortal
                  options={dataList.unitList}
                  getOptionLabel={(option) => option.UnitName}
                  // renderInput={(params) => <StyledInputBase {...params} />}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <StyledInputBase type="text" {...params.inputProps} />
                    </div>
                  )}
                  PaperComponent={CustomPaper}
                />
              )}
            </VBTableCellBody>
            <VBTableCellBody>
              {ind === 0 ? (
                "1"
              ) : (
                <StyledInputBase
                  type="number"
                  value={ele.MultiFactor}
                  onChange={(e) => {
                    handleChangeFun(e.target.value, ind, "MultiFactor");
                  }}
                />
              )}
            </VBTableCellBody>
            <VBTableCellBody>
              {ind === 0 ? (
                " "
              ) : (
                <StyledInputBase
                  type="number"
                  value={ele.ReverseFactor}
                  onChange={(e) => {
                    handleChangeFun(e.target.value, ind, "ReverseFactor");
                  }}
                />
              )}
            </VBTableCellBody>
            <VBTableCellBody>
              {ind === 0 ? (
                ele.PurchasePrice
              ) : (
                <StyledInputBase
                  type="number"
                  value={ele.PurchasePrice}
                  onChange={(e) => {
                    handleChangeFun(e.target.value, ind, "PurchasePrice");
                  }}
                />
              )}
            </VBTableCellBody>
            <VBTableCellBody>
              {ind === 0 ? (
                ele.SalesPrice
              ) : (
                <StyledInputBase
                  type="number"
                  value={ele.SalesPrice}
                  onChange={(e) => {
                    handleChangeFun(e.target.value, ind, "SalesPrice");
                  }}
                />
              )}
            </VBTableCellBody>
            <VBTableCellBody>
              {ind === 0 ? (
                ele.Barcode
              ) : (
                <StyledInputBase
                  value={ele.Barcode}
                  onChange={(e) => {
                    handleChangeFun(e.target.value, ind, "Barcode");
                  }}
                />
              )}
            </VBTableCellBody>
            <VBTableCellBody>
              {
                <StyledInputBase
                  value={ele.SalesPrice1}
                  onChange={(e) => {
                    handleChangeFun(e.target.value, ind, "SalesPrice1");
                  }}
                />
              }
            </VBTableCellBody>
            <VBTableCellBody>
              {
                <StyledInputBase
                  value={ele.SalesPrice2}
                  onChange={(e) => {
                    handleChangeFun(e.target.value, ind, "SalesPrice2");
                  }}
                />
              }
            </VBTableCellBody>
            <VBTableCellBody>
              {
                <StyledInputBase
                  value={ele.SalesPrice3}
                  onChange={(e) => {
                    handleChangeFun(e.target.value, ind, "SalesPrice3");
                  }}
                />
              }
            </VBTableCellBody>
            <VBTableCellBody sx={{ width: "fit-content" }}>
              <TableSwitch
                sx={{ mx: "auto" }}
                checked={ele.UnitInSales}
                onChange={(e) => {
                  handleChangeFun(e.target.checked, ind, "UnitInSales");
                }}
              />
            </VBTableCellBody>
            <VBTableCellBody sx={{ width: "fit-content" }}>
              <TableSwitch
                sx={{ mx: "auto" }}
                checked={ele.UnitInPurchase}
                onChange={(e) => {
                  handleChangeFun(e.target.checked, ind, "UnitInPurchase");
                }}
              />
            </VBTableCellBody>
            <VBTableCellBody sx={{ width: "fit-content" }}>
              <TableSwitch
                sx={{ mx: "auto" }}
                checked={ele.UnitInReports}
                onChange={(e) => {
                  handleChangeFun(e.target.checked, ind, "UnitInReports");
                }}
              />
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "center" }}>
              {ind !== 0 && (
                <>
                  <IconButton
                    id="basic-button"
                    color="secondary"
                    aria-controls={openAction ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAction ? "true" : undefined}
                    sx={{ p: 0 }}
                    onClick={(event) => {
                      setisAction(event.currentTarget);
                      setSelectedIndex(ind);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={isAction}
                    open={openAction && selectedIndex === ind}
                    onClose={() => {
                      setisAction(null);
                      setSelectedIndex(null);
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setDataDetail({
                          ...dataDetail,
                          multiUnit: dataDetail.multiUnit.filter(
                            (fe, fi) => fi !== ind
                          ),
                        });
                        setisAction(null);
                        setSelectedIndex(null);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </>
              )}
            </VBTableCellBody>
          </TableRow>
        ))}
        <Button
          color="secondary"
          onClick={() => {
            AddNewMultiUnitLine();
          }}
        >
          <span style={{ fontSize: "18px", marginRight: "6px" }}>+</span> Add a
          line
        </Button>
        <TableRow></TableRow>
      </TableBody>
    </VBTableContainer>
  );
}

export default MultiUnits;

const StyledInputBase = styled(InputBase)(() => ({
  fontSize: "12px",
  width: 80,

  // If you don't like the box shadow just comment the below two lines of code 😊😊😊
}));

const CustomPaper = styled(Paper)(() => ({
  width: "250px", // Set your desired width
}));
