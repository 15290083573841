import axios from "axios";

export const callStockTransferPageDetailsAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/stock-transfer/stock-transfer-page-details/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const callProductGroupAPI = async function (body) {
  try {
    const { data } = await axios.post("/api/v10/productGroups/productGroups/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const callStockTransferFilterAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "/api/v11/stock-transfer/stock-transfer-filter/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const callGetProductByBarcode = async function (body) {
  try {
    const { data } = await axios.post("api/v11/products/get-product/barcode/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const callCreateStockTransferAPI= async function (body) {
  try {
    const { data } = await axios.post(
      "/api/v10/stockTransfers/create-stockTransfer/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};
