import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import {
  Autocomplete,
  Button,
  IconButton,
  InputBase,
  Paper,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { formatNumber } from "../../../../generalFunction";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";

const Bom = ({
  dataDetail,
  setDataDetail,
  dataList,
  AddNewBomList,
  callBomProdUnit,
}) => {
  const [t] = useTranslation("common");
  const [inputDisable, setInputDisable] = useState(false)
  
  const testState = useState(null)
  console.log(testState, "this is one");
  

  const handleProductGroup = async function (v, ind) {
    let updatedList;

    if (v?.id) {
      setInputDisable(true)
      console.log(inputDisable," how os this working in here");
      
      try {
        const res = await callBomProdUnit(v.id);
        if (res) {
          updatedList = dataDetail.BomList.map((item, i) => ({
            ...item,
            // UnitName: null,
            ProductName: i === ind ? v : item.ProductName,
            UnitList: i === ind ? res.UnitList : item.UnitList,
          }));
        }
      } catch (error) {
        console.error("Error calling BomProdUnit:", error);
      }
    } else {
      // No ID case
      updatedList = dataDetail.BomList.map((item, i) => ({
        ...item,
        UnitName: i === ind ? null : item.UnitName,
        ProductName: i === ind ? v : item.ProductName,
        UnitList: i === ind ? [] : item.UnitList,
      }));
    }
  
    // Always set state after processing
    setDataDetail((prev) => ({
      ...prev,
      BomList: updatedList || prev.BomList,
    }));
    setInputDisable(false)
  };

  const handleUnitName = function (val, ind) {
    let updatedList = dataDetail.BomList;
    updatedList = dataDetail.BomList.map((e, i) => ({
      ...e,
      UnitName: i === ind ? val : e.UnitName,
    }));
    setDataDetail({
      ...dataDetail,
      BomList: updatedList,
    });
  };

  return (
    <Paper
      sx={{ width: "100%", height: "100%", position: "relative" }}
      elevation={0}
    >
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="sticky table" sx={{ height: "100%" }}>
          <VBTableHeader>
            <VBTableCellHeader sx={{ textTransform: "uppercase", width: "5%" }}>
              {t("SI NO")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textTransform: "uppercase", width: "30%" }}>
              {t("Product Name")}
            </VBTableCellHeader>
            <VBTableCellHeader
              sx={{ textTransform: "uppercase", width: "20%" }}
            >
              {t("Quantity")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textTransform: "uppercase", width: "20%" }}>
              {t("Unit")}
            </VBTableCellHeader>
            <VBTableCellHeader
              sx={{ textTransform: "uppercase", width: "10%" }}
            >
              {t("Rate")}
            </VBTableCellHeader>
            <VBTableCellHeader
              sx={{ textTransform: "uppercase", width: "10%" }}
            >
              {t("Amount")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ width: "5%" }}> </VBTableCellHeader>
          </VBTableHeader>
          <TableBody>
            {dataDetail.BomList.map((ele, ind) => (
              <TableRow key={ind + 1}>
                <VBTableCellBody>{ind + 1}</VBTableCellBody>

                <VBTableCellBody>
                  <Autocomplete
                    disablePortal
                    clearOnEscape
                    options={dataList.productList}
                    getOptionLabel={(option) => option.ProductName}
                    value={ele.ProductName}
                    filterOptions={(x) => x}
                    onInputChange={(e, value) => {
                      if (!inputDisable) {
                        setDataDetail((prev) => ({
                          ...prev,
                          searchBomText: value,
                        }));
                      } 
                    }}
                    onChange={(e, v) => {
                      setInputDisable(true)
                      handleProductGroup(v, ind);
                    }}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                    PaperComponent={CustomPaper}
                  />
                </VBTableCellBody>
                <VBTableCellBody>
                  <StyledInputBase
                    type="number"
                    value={ele.MultiFactor}
                    onChange={(e) => {
                      // handleChangeFun(e.target.value, ind, "MultiFactor");
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody>
                  <Autocomplete
                    disablePortal
                    value={ele.UnitName}
                    options={ele.UnitList}
                    getOptionLabel={(option) => option.UnitName}
                    // renderInput={(params) => <StyledInputBase {...params} />}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                    onChange={(evt, val) => {
                      handleUnitName(val, ind);
                    }}
                    PaperComponent={CustomPaper}
                  />
                </VBTableCellBody>
                <VBTableCellBody>{ele.UnitPrice}</VBTableCellBody>
                <VBTableCellBody>{ele.NetAmount}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right" }}>
                  {ind !== 0 && (
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={(event) => {
                        const delitedList = dataDetail.BomList.filter(
                          (e, i) => i !== ind
                        );
                        setDataDetail({
                          ...dataDetail,
                          BomList: delitedList,
                          searchBomText: "",
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </VBTableCellBody>
              </TableRow>
            ))}
            <Button
              color="secondary"
              sx={{ width: "110px" }}
              onClick={() => {
                AddNewBomList();
              }}
            >
              <span style={{ fontSize: "18px", marginRight: "6px" }}>+</span>{" "}
              Add a line
            </Button>
            <TableRow></TableRow>
          </TableBody>

          <VBTableFooter>
            <TableRow>
              <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
              <VBTableCellFooter> </VBTableCellFooter>
              <VBTableCellFooter>{formatNumber(0)}</VBTableCellFooter>
              <VBTableCellFooter> </VBTableCellFooter>
              <VBTableCellFooter>{formatNumber(0)}</VBTableCellFooter>
              <VBTableCellFooter>{formatNumber(0)}</VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Bom;
const StyledInputBase = styled(InputBase)(() => ({
  fontSize: "12px",
  width: "100%",
}));

const StyledTextField = styled(TextField)(() => ({
  width: "100%",
  "& .MuiInputBase-input": {
    fontSize: "12px",
  }
}));

const CustomPaper = styled(Paper)(() => ({
  width: "100%", // Set your desired width
}));
